@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

$dark-blue: #012a4a;
$light-blue: #59899b;
$dark-orange: #c05e00;
$light-orange: #f98c19;
$dark-gray: #7e7e7e;
$light-gray: #f6f6f6;

::-webkit-scrollbar {
    display: none;
}

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    background: url("./assets/images/watermark.jpg");
    background-size: cover;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.dark-blue-text {
    color: $dark-blue;
}
.dark-orange-text {
    color: $dark-orange;
}
.dark-gray-text {
    color: $dark-gray;
}
.light-blue-text {
    color: $light-blue;
}
.light-orange-text {
    color: $light-orange;
}
.light-gray-text {
    color: $light-gray;
}
.dark-blue-bg {
    background-color: $dark-blue;
    color: $light-gray;
}
.dark-orange-bg {
    background-color: $dark-orange;
    color: $light-gray;
}
.dark-gray-bg {
    background-color: $dark-gray;
    color: $light-gray;
}
.light-blue-bg {
    background-color: $light-blue;
    color: $light-gray;
}
.light-orange-bg {
    background-color: $light-orange;
    color: $light-gray;
}
.light-gray-bg {
    background-color: $light-gray;
    color: $dark-blue;
}
.gradient-blue {
    background-image: linear-gradient(to right, $light-blue, $dark-blue);
    color: $light-gray;
}
.gradient-blue-top-light {
  background-image: linear-gradient(to bottom, $light-blue, $dark-blue);
  color: $light-gray;
}
.gradient-orange {
    background-image: linear-gradient(to right, $light-orange, $dark-orange);
    color: $light-gray;
}

// nav a.active{
//   background-color: #012a4a;
//   color: #f6f6f6;
// }

nav .activeLink a.active{
  background-color: $light-blue;
  color: #f6f6f6;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  height: 53px;
  width: "150%";
}

nav .activeCollapseLink a.active{
    background-color: $light-blue;
    color: #f6f6f6;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    height: 53px;
    width: 53px;
  }

.toggle-checkbox:checked {

  right: 0;
  border-color: $light-orange;
}
.toggle-checkbox:checked + .toggle-label {

  background-color: $light-orange;
}